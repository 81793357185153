<template>
  <div>
    <div>
      <div class="form-group">
        <label for="exampleFormControlSelect1">
          <h5 style="width: 200px">
            <i class="bi bi-person-plus"></i> 마을 출석 현황
          </h5>
        </label>
        <select
          class="form-control"
          id="holiday"
          name="holiday"
          v-model="holiday"
          @change="getTotalAttend()"
        >
          <option id="" value="00">날짜를 선택하세요.</option>
          <option
            v-for="daylist in dayLists"
            :key="daylist.dday"
            v-bind:value="daylist.dday"
            :id="daylist.dday"
            :selected="daylist.dday == daylist.cday"
          >
            {{ daylist.dday }}
          </option>
        </select>
      </div>
    </div>

    <!-- 출석 인원 -->
    <div style="height: 20px;"></div>
    <div class="card">
      <h5 class="card-header">출석인원</h5>
      <div class="card-body">
        <p class="card-text">
          <table class="table table-bordered" style="font-size: 14px;">
            <thead style="font-size: 12px;">
              <tr style="text-align: center; background-color:#007bff45;">
                <th scope="col" style="width: 15%; padding: 3px;" rowspan="2">셀</th>
                <th scope="col" style="width: 10%; padding: 3px;" rowspan="2">제적</th>
                <th scope="col" colspan="2" style="width: 20%; padding: 3px;">예배 출석</th>
                <th scope="col" colspan="2" style="width: 20%; padding: 3px;">셀 출석</th>
                <th scope="col" style="width: 15%; padding: 3px;" rowspan="2">비율(셀)</th>
              </tr>
              <tr style="text-align: center; background-color:#007bff45;">
                <th style="width: 10%;">남</th>
                <th style="width: 10%;">여</th>
                <th style="width: 10%;">남</th>
                <th style="width: 10%;">여</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in enrollList" :key="item.id" style="text-align: center;">
                <td style="font-size: 12px;">{{ item.name }}</td>
                <td style="font-size: 12px;">{{ item.total_students }}</td>
                <td style="font-size: 12px;">{{ item.male_attend_count }}</td>
                <td style="font-size: 12px;">{{ item.female_attend_count }}</td>
                <td style="font-size: 12px;">{{ item.male_cellattend_count }}</td>
                <td style="font-size: 12px;">{{ item.female_cellattend_count }}</td>
                <td style="font-size: 12px;">
                  <span :style="{ color: getCellRate(item) > 60 ? 'green' : 'red' }">
                    {{ getCellRate(item) }}%
                  </span>
                
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr style="text-align: center; background-color: #f8f9fa; font-weight: bold;">
                <td>합계</td>
                <td>{{ sumTotalStudents }}</td>
                <td>{{ sumMaleAttendCount }}</td>
                <td>{{ sumFemaleAttendCount }}</td>
                <td>{{ sumMaleCellAttendCount }}</td>
                <td>{{ sumFemaleCellAttendCount }}</td>
                <td style="font-size: 12px;">
                  <span :style="{ color: getTotalCellRate() > 60 ? 'green' : 'red' }">
                    {{ getTotalCellRate() }}%
                  </span>
                </td>
              </tr>
            </tfoot>
          </table>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import manageService from "../services/manageService";

export default {
  name: "list",
  data() {
    return {
      enrollList: [],
      dayLists: [],
      holiday: "00",
      sumTotalStudents: 0,
      sumMaleAttendCount: 0,
      sumFemaleAttendCount: 0,
      sumMaleCellAttendCount: 0,
      sumFemaleCellAttendCount: 0,
    };
  },
  methods: {
    getNum(val) {
      return isNaN(val) ? 0 : val;
    },
    getDayList() {
      manageService
        .getDaysList()
        .then((response) => {
          this.dayLists = response.data.slice(0, 15);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getTotalAttend() {
      if (this.holiday === "00") return;

      const data = {
        attenddate: this.holiday,
        classid: this.$store.state.classid,
      };

      manageService
        .gettotalattend(data)
        .then((response) => {
          this.enrollList = response.data;

          this.sumTotalStudents = this.enrollList.reduce((sum, item) => sum + parseInt(item.total_students || 0), 0);
          this.sumMaleAttendCount = this.enrollList.reduce((sum, item) => sum + parseInt(item.male_attend_count || 0), 0);
          this.sumFemaleAttendCount = this.enrollList.reduce((sum, item) => sum + parseInt(item.female_attend_count || 0), 0);
          this.sumMaleCellAttendCount = this.enrollList.reduce((sum, item) => sum + parseInt(item.male_cellattend_count || 0), 0);
          this.sumFemaleCellAttendCount = this.enrollList.reduce((sum, item) => sum + parseInt(item.female_cellattend_count || 0), 0);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getCellRate(item) {
      if (!item.total_students || item.total_students === 0) return 0;
      const rate = Math.ceil(((parseInt(item.male_cellattend_count) + parseInt(item.female_cellattend_count)) / parseInt(item.total_students)) * 100);
      return Math.min(rate, 100); // 최대 100% 제한
    },
    getTotalCellRate() {
      if (!this.sumTotalStudents || this.sumTotalStudents === 0) return 0;
      const rate = Math.ceil(((this.sumMaleCellAttendCount + this.sumFemaleCellAttendCount) / this.sumTotalStudents) * 100);
      return Math.min(rate, 100); // 최대 100% 제한
    }
  },
  mounted() {
    this.getDayList();
  },
};
</script>

<style scoped>
.card-body {
  padding: 0.5rem;
}
</style>
